<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">内容配置</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <a-tabs default-active-key="1">
                        <a-tab-pane key="1" tab="首页">
                            <quill-editor v-model="homeContent" class="custom-editor"></quill-editor>
                            <a-button @click="saveConfig('home')" type="primary" :loading="btnLoading" class="save-btn">
                                保存
                            </a-button>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="关注">
                            <quill-editor v-model="followContent" class="custom-editor"></quill-editor>
                            <a-button @click="saveConfig('follow')" type="primary" :loading="btnLoading"
                                class="save-btn">
                                保存
                            </a-button>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="板块">
                            <quill-editor v-model="classifyContent" class="custom-editor"></quill-editor>
                            <a-button @click="saveConfig('classify')" type="primary" :loading="btnLoading"
                                class="save-btn">
                                保存
                            </a-button>
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="看板">
                            <quill-editor v-model="sumContent" class="custom-editor"></quill-editor>
                            <a-button @click="saveConfig('sum')" type="primary" :loading="btnLoading"
                                class="save-btn">
                                保存
                            </a-button>
                        </a-tab-pane>
                        <a-tab-pane key="5" tab="设置">
                            <quill-editor v-model="settingContent" class="custom-editor"></quill-editor>
                            <a-button @click="saveConfig('setting')" type="primary" :loading="btnLoading"
                                class="save-btn">
                                保存
                            </a-button>
                        </a-tab-pane>
                    </a-tabs>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>

import { getHomeConfig, setHomeConfig } from "@/api/config.js";
export default ({
    components: {
    },
    data() {
        return {
            btnLoading: false,
            homeContent: '', // 绑定的文本内容
            followContent: '', // 绑定的文本内容
            classifyContent: '', // 绑定的文本内容
            sumContent: '', // 绑定的文本内容
            settingContent: '', // 绑定的文本内容
        }
    },
    mounted() {
        this.getHomeConfig();
    },
    methods: {
        saveConfig(type) {
            this.btnLoading = true;
            let content = '';
            if (type == 'home') {
                content = this.homeContent;
            } else if (type == 'follow') {
                content = this.followContent;
            } else if (type == 'classify') {
                content = this.classifyContent;
            } else if (type == 'sum') {
                content = this.sumContent;
            } else if (type == 'setting') {
                content = this.settingContent;
            }
            setHomeConfig({ content: content, type }).then(res => {
                this.btnLoading = false;
                this.$message.success({ content: '保存成功！', duration: 2 });
            }).catch(err => {
                this.btnLoading = false;
            })
        },
        getHomeConfig() {
            getHomeConfig().then(res => {
                let data = res.data.data;
                if (data.length > 0) {
                    data.forEach(item => {
                        if (item.type == 'home') {
                            this.homeContent = item.content;
                        } else if (item.type == 'follow') {
                            this.followContent = item.content;
                        } else if (item.type == 'classify') {
                            this.classifyContent = item.content;
                        } else if (item.type == 'sum') {
                            this.sumContent = item.content;
                        } else if (item.type == 'setting') {
                            this.settingContent = item.content;
                        }
                    });
                }
            })
        }
    }
})

</script>

<style lang="scss"  scoped>

.ql-editor {
    height: 700px;
}

::v-deep .ant-card-body {
    padding: 0 20px 20px 20px !important;
}

.save-btn {
    margin-top: 20px;
}
</style>